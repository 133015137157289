<template>
  <div v-show="visible">
    <div class="fades" @click="onClose"></div>
    <div class="pop pop-tips animate__animated animate__fadeIn">
      <div class="ic pop-top">温馨提示</div>
      <div class="pop-cont">
        <h3 class="p-title">亲爱的玩家：</h3>
        <div class="cont-t">
          <p>一将成名2024活动投稿于2024年6月27日09:00正式开始，欢迎大家准时参与！</p>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import '@/assets/css/pop.css';
export default {
  name: 'PopTips',
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    onClose() {
      this.$emit('hidePop');
    },
  },
};
</script>
<style lang="less" scoped>
.pop-top {
  background-position: 0 0;
  border-right: 0.02rem solid #a38b5c;
  border-left: 0.02rem solid #a38b5c;
  background: url(../../assets/images/popu-header.png) no-repeat;
  background-size: cover;
}
.pop-cont {
  background-color: #faf8f0;
  .p-title {
    color: #6e5a46;
  }
  .cont-t {
    font-family: SourceHanSansCN-Medium;
    font-size: 0.2rem;
    font-weight: normal;
    font-stretch: normal;
    line-height: 0.5rem;
    padding-left: 0rem;
    letter-spacing: 0px;
    p {
      padding: 0;
    }
  }
  .btn-cancle {
    background: #faf8f0 url(../../assets/images/cancel.png) no-repeat;
    background-size: cover;
  }
  .btn-ok {
    background: #faf8f0 url(../../assets/images/confirm.png) no-repeat;
    background-size: cover;
  }
}
</style>
