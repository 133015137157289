<template>
  <div class="container" :class="agent == 'h5' ? 'h5' : ''">
    <!-- <div class="men" v-show="isLoading">
      <div class="men-l ml-out-left"></div>
      <div class="progress-bar">{{ progress }}%</div>
      <div class="men-r ml-out-right"></div>
    </div> -->
    <div class="black ml-fade-out" v-show="isLoading"></div>

    <Header :now="1" ref="header" />
    <swiper :options="swiperOption" ref="mySwiper">
      <swiper-slide class="swiper-no-swiping">
        <div class="slide slide1">
          <div class="active_time"></div>
          <a href="javascript:;" class="ic-btn btn-in" @click="onJumpFormIn()">立即投稿</a>
          <!-- <div class="arrow-down ml-swing-down"></div> -->
          <div class="arrow-down"></div>
        </div>
      </swiper-slide>
      <swiper-slide class="swiper-no-swiping">
        <div class="slide slide2">
          <div class="box">
            <div class="title animate__animated" :class="swiperIndex == 1 ? 'animate__fadeInLeft' : ''">
              2024武将命题介绍：
            </div>
            <div class="des animate__animated" :class="swiperIndex == 1 ? 'animate__fadeInLeft show3' : ''">
              三国杀一将成名——与万千用户共创武将设计，2024
              武将原创设计大赛将于2024年6月27日盛大启幕。历史长河中，谋士们以历史为墨，以智为笔，挥洒下一个个活灵活现的战术；巧匠则以奇为锤，以技为砧，敲击打制出一件件惊世机关。本届大赛由<span
                style="color: #ffeac1">谋贾诩</span>引领，首次开发谋系列武将及<span
                style="color: #ffeac1">全新锦囊牌</span>作为命题，我们期待与无数玩家创意的共鸣中，碰撞出新锐的技能，共绘出三国杀的新章。
            </div>
            <div class="role-zgl animate__animated" :class="swiperIndex == 1 ? 'animate__fadeInUpBig show3' : ''"></div>
            <a v-if="isOpen" href="https://web.sanguosha.com/login/index.html" class="location animate__animated"
              :class="swiperIndex == 1 ? 'animate__bounceInUp show3' : ''" />
            <div class="arrow-down" :class="[swiperIndex == 1 ? ' ' : '', arrow]"></div>
          </div>
        </div>
      </swiper-slide>
      <swiper-slide class="swiper-no-swiping">
        <div class="slide slide3">
          <div class="box">
            <a href="javascript:;" class="role-one animate__animated animate__fast"
              :class="swiperIndex == 2 ? 'animate__fadeInDown' : ''" @click="onJumpForm(1)">
              <div class="role-hover">


                <div class="btn-tg"></div>
              </div>
            </a>
            <div class="yuansu animate__animated" :class="swiperIndex == 2 ? 'animate__fadeIn' : ''"></div>
            <a href="javascript:;" class="role-two animate__animated"
              :class="swiperIndex == 2 ? 'animate__fadeInDown' : ''" @click="onJumpForm(2)">
              <div class="role-hover">


                <div class="btn-tg"></div>
              </div>
            </a>
          </div>
          <!-- <div class="arrow-down" v-if="arrowShow"></div> -->
        </div>
      </swiper-slide>
      <swiper-slide class="swiper-no-swiping">
        <div class="slide slide3 slide4">
          <div class="box">
            <a href="javascript:;" class="role-one animate__animated animate__fast"
              :class="swiperIndex == 3 ? 'animate__fadeInDown' : ''" @click="onJumpForm(4)">
              <div class="role-hover-third">
                <!-- <div class="role-num">
                  <div class="type">斗地主武将</div>

                  <div class="name">荀 彧 荀 攸</div>
                  <div class="english">XUN YU XUN YOU</div>
                  <div class="line"></div>
                </div>
                <div class="role-men">
                  <img src="~/assets/images/role3_rw.png" />
                </div> -->

                <div class="btn-tg"></div>
              </div>
            </a>
            <div class="yuansu animate__animated" :class="swiperIndex == 3 ? 'animate__fadeIn' : ''"></div>
            <a href="javascript:;" class="role-two animate__animated"
              :class="swiperIndex == 3 ? 'animate__fadeInDown' : ''" @click="onJumpForm(3)">
              <div class="role-hover-fourth">
                <!-- <div class="role-num">
                  <div class="type">锦囊牌</div>
                  <div class="name">望梅止渴</div>
                  <div class="english">WANG MEI ZHI KE</div>
                  <div class="line"></div>
                </div>
                <div class="role-men">
                  <img src="~/assets/images/role4_rw.png" />
                </div> -->

                <div class="btn-tg"></div>
              </div>
            </a>
          </div>
          <div class="arrow-down" v-if="arrowShow"></div>
        </div>
      </swiper-slide>
      <swiper-slide class="swiper-no-swiping">
        <div class="slide slide5">
          <div class="box">
            <a href="javascript:;" class="pw pw-pic animate__animated"
              :class="swiperIndex == 4 ? 'animate__fadeInDown' : ''">
              <div class="name-pw">Kayak</div>
              <div class="text-pw">评委</div>

              <div class="position">
                游卡桌游创意总监
                <br />三国杀总设计师
              </div>
            </a>
            <div class="pw">
              <a href="javascript:;" class="btn-hdzq animate__animated"
                :class="swiperIndex == 4 ? 'animate__backInDown' : ''" @click="onJumpList">互动专区</a>
              <p class="text animate__animated" :class="swiperIndex == 4 ? 'animate__backInUp' : ''">
                点击前往互动专区参与共评赢好礼
              </p>
            </div>
            <a href="javascript:;" class="pw pw-pic pic2 animate__animated"
              :class="swiperIndex == 4 ? 'animate__fadeInDown' : ''">
              <div class="name-pw">韩 旭</div>
              <div class="text-pw">评委</div>

              <div class="position">
                三国杀武将技能设计师
                <br />三国杀竞技玩法创始人 <br />规则负责人
              </div>
            </a>
          </div>
          <div class="arrow-down"></div>
        </div>
      </swiper-slide>
      <div class="swiper-pagination nav-index" :class="this.swiperIndex > 0 ? 'nav-next' : 'none'" slot="pagination">
      </div>
    </swiper>
    <!-- 活动未开始弹框 -->
    <Pop-tips :visible="isShowPopTips" @hidePop="hidePop" />
  </div>
</template>

<script>
// @ is an alias to /src
import Header from "../components/content/header.vue";
import Login from "../components/content/login.vue";
import { judgeWeb } from "@/common/util";

import PopTips from "@/components/content/PopTips";
import { config } from "@/common/const";

let myVue = {};
export default {
  name: "Index",
  components: {
    Header,
    Login,
    PopTips,
  },
  computed: {
    swiper() {
      return this.$refs.mySwiper.swiper;
    },
    isOpen: function () {
      const currentDate = new Date();
      const year = currentDate.getFullYear();
      // 确保我们关注的是2024年
      if (year > 2024) {
        return true;
      }
      const month = currentDate.getMonth() + 1; // getMonth() 返回0-11，所以需要+1
      const day = currentDate.getDate();
      console.log(year, month, day);
      if (month < 6) {
        return false;
        // 在7月8日之前
      } else if (month === 6) {
        if (day < 27) {
          return false;
        } else {
          return true;
        }
      } else {
        return true; // 7月19日及以后
      }
    },
  },
  data() {
    return {
      agent: "pc", //是手机访问还是电脑
      token: 0, //是否登录
      //首页动画相关
      isLoading: true,
      progress: 0,
      swiperIndex: 0,
      arrow: "",
      // 轮播图配置（具体配置可参考http://t.zoukankan.com/xiaocaiyuxiaoniao-p-10521462.html）
      swiperOption: {
        parallax: true, //视差效果
        mousewheel: true,
        noSwiping: false,
        direction: "vertical",
        loop: false,
        effect: "cards",
        pagination: {
          el: ".swiper-pagination",
          type: "bullets",
          progressbarOpposite: true, //使进度条分页器与Swiper的direction参数相反
          bulletElement: "li", //设定分页器指示器（小点）的HTML标签。
          clickable: true, //此参数设置为true时，点击分页器的指示点分页器会控制Swiper切换
          bulletClass: "bullet",
          bulletActiveClass: "active",
        },
        observer: true, //修改swiper自己或子元素时，自动初始化swiper
        observeParents: true, //修改swiper的父元素时，自动初始化swiper
        on: {
          slideChangeTransitionEnd: function () {
            //切换结束时，告诉我现在是第几个slide
            myVue.swiperIndex = this.activeIndex;
            //入场完后 箭头加上下抖动效果
            if (this.activeIndex == 1) {
              setTimeout(() => {
                myVue.arrow = "ml-swing-down";
              }, 300);
            }
          },
        },
      },
      arrowShow: true,

      isGoSubmit: false, //是否可投稿
      isShowPopTips: false, //开始投稿日期提示弹框
      timer: null, //定时器
    };
  },

  mounted() {
    //判断pc还是h5
    this.agent = 'pc';
    //定时开启开赛日
    this.timer = setInterval(this.funRtime, 1000);

    //接口401（未登录）带过来的参数0未登录
    // let { login = 1 } = this.$route.query;
    this.token = localStorage.getItem("token");
    if (!this.token && this.agent == "pc") {
      //入场开门动画
      setTimeout(() => {
        this.isLoading = false;
      }, 1200);
      setInterval(() => {
        if (this.progress < 100) {
          this.progress += 10;
        }
      }, 100);
    } else {
      this.isLoading = false;
    }
  },
  methods: {
    //倒计时活动开始日期
    funRtime() {
      var startTime = new Date(config.startTime);
      var NowTime = new Date();
      if (NowTime >= startTime) {
        //活动开始了
        console.log("开始了index");
        this.isGoSubmit = true;
        clearInterval(this.timer);
      } else {
        console.log("没开始index");
        this.isGoSubmit = false;
      }
    },
    hidePop() {
      this.isShowPopTips = false;
    },
    //箭头显示否
    mouseover() {
      this.arrowShow = false;
    },
    mouseout() {
      this.arrowShow = true;
    },
    onJumpFormIn() {
      this.swiper.slideTo(2);
    },
    //跳转投稿
    onJumpForm(role) {
      if (this.isGoSubmit) {
        if (this.token) {
          localStorage.removeItem("workdetail");
          this.$router.push({ path: "/form", query: { role } });
        } else {
          this.$refs.header.showLogin();
        }
      } else {
        this.isShowPopTips = true;
      }
    },
    onJumpList() {
      this.$router.push({ path: "/list" });
    },
  },
  beforeCreate() {
    myVue = this;
  },
  destroyed() {
    clearInterval(this.timer);
  },
};
</script>
<style lang="less" scoped>
.active_time {
  position: absolute;
  bottom: 3rem;
  width: 2.12rem;
  height: 0.23rem;
  background: url(../assets/images/active_time.png) no-repeat;
  background-size: 100% auto;
}

.none {
  display: none;
}

.position {


  font-family: MicrosoftYaHei;
  font-size: 0.14rem;
  font-weight: normal;
  font-stretch: normal;
  line-height: 0.22rem;
  letter-spacing: 0px;
  color: #c6eaf2;

}

.role-two {
  // opacity: 0;
  position: relative;
  z-index: 0;
  display: flex;
  justify-content: center;
  width: 5.85rem;
  height: 100%;

  // background: rgba(0, 0, 0, 0.7);
  // border-right: 0.01rem solid rgba(176, 29, 31, 0.4);
  .role-pic {
    width: 0.49rem;
    height: 3.19rem;
    margin-top: 2.4rem;
  }

  .role-hover {
    // display: none;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: absolute;
    // left: 0;
    top: -0rem;
    // right: 0;
    bottom: 0;
    z-index: 10;
    width: 4.6rem;
    height: 8.77rem;
    background: url(../assets/images/flag2.png) no-repeat top;
    background-size: 100% auto;

    .btn-tg {
      position: absolute;
      bottom: 2.59rem;
      left: calc(50% - 2.51rem / 2);
      width: 2.51rem;
      height: 0.58rem;
      margin: 0 auto;
      background: url(../assets/images/btn_tg.png) no-repeat center;
      background-size: 2.51rem auto;
    }

    .btn-tg:hover {
      background: url(../assets/images/btn_tg_hover.png) no-repeat center;
      background-size: 2.51rem auto;
    }
  }
}

.role-hover-third {
  position: absolute;
  // left: 0;
  top: -0.0rem;
  // right: 0;
  bottom: 0;
  z-index: 10;
  width: 4.42rem;
  height: 7.6rem;
  background: url(../assets/images/left_flag_3.png) no-repeat top;
  background-size: 100% auto;

  .btn-tg {
    position: absolute;
    bottom: 1.6rem;
    left: calc(50% - 2.51rem / 2);
    width: 2.51rem;
    height: 0.58rem;
    margin: 0 auto;
    background: url(../assets/images/btn_tg.png) no-repeat center;
    background-size: 2.51rem auto;
  }

  .btn-tg:hover {
    background: url(../assets/images/btn_tg_hover.png) no-repeat center;
    background-size: 2.51rem auto;
  }

  .role-num {
    width: 100%;
    // height: 2.53rem;
    margin-top: 0.59rem;

    .number {
      font-size: 0.4rem;
      color: #000;
      font-family: Arial, Helvetica, sans-serif;
    }

    .line {
      width: 2.26rem;
      height: 0.11rem;

      margin: 0.2rem auto;
      background: url(../assets/images/new-line.png);
      background-size: cover;
    }

    .name {
      font-size: 0.26rem;
      font-weight: bold;
      color: #edfcff;
    }

    .english {
      font-size: 0.16rem;
      color: #edfcff;
      text-transform: Uppercase;
    }
  }

  .role-men {
    display: flex;
    justify-content: center;
    height: 3.5rem;

    img {
      margin-left: 0.2rem;
      // width: 3.2rem;
      transform: scale(0.9);
      margin-top: -0.2rem;
    }
  }

  .type {
    font-family: MicrosoftYaHei;
    font-size: 20px;
    font-weight: normal;
    font-stretch: normal;
    line-height: 36px;
    letter-spacing: 1px;
    color: #e8cf9e;
  }
}

.role-hover-fourth {
  position: absolute;
  // left: 0;
  top: -0.0rem;
  // right: 0;
  bottom: 0;
  z-index: 10;
  width: 4.42rem;
  height: 7.6rem;
  background: url(../assets/images/right_flag_4.png) no-repeat top;
  background-size: 100% auto;

  .btn-tg {
    position: absolute;
    bottom: 1.6rem;
    left: calc(50% - 2.42rem / 2);
    width: 2.42rem;
    height: 0.58rem;
    margin: 0 auto;
    background: url(../assets/images/btn_tg.png) no-repeat center;
    background-size: 100% auto;
  }

  .role-men {
    display: flex;
    justify-content: center;
  }

  .btn-tg:hover {
    background: url(../assets/images/btn_tg_hover.png) no-repeat center;
    background-size: 100% auto;
  }

  .role-num {
    width: 100%;
    // height: 2.53rem;
    margin-top: 0.59rem;


    .number {
      font-size: 0.4rem;
      color: #000;
      font-family: Arial, Helvetica, sans-serif;
    }

    .line {
      width: 2.26rem;
      height: 0.11rem;

      margin: 0.2rem auto;
      background: url(../assets/images/new-line.png);
      background-size: cover;
    }

    .name {
      font-size: 0.26rem;
      font-weight: bold;
      color: #edfcff;
    }

    .english {
      font-size: 0.16rem;
      color: #edfcff;
      text-transform: Uppercase;
    }
  }

  .role-men {
    height: 3.5rem;

    img {
      margin-top: -0.2rem;
      // width: 3.2rem
      transform: scale(0.9);
    }
  }

  .type {
    font-family: MicrosoftYaHei;
    font-size: 20px;
    font-weight: normal;
    font-stretch: normal;
    line-height: 36px;
    letter-spacing: 1px;
    color: #e8cf9e;
  }
}

.role-hover {
  .role-num {
    width: 100%;
    height: 2.53rem;
    margin-top: 0.77rem;

    .number {
      font-size: 0.4rem;
      color: #000;
      font-family: Arial, Helvetica, sans-serif;
    }

    .line {
      width: 2.26rem;
      height: 0.11rem;

      margin: 0.2rem auto;
      background: url(../assets/images/new-line.png);
      background-size: cover;
    }

    .name {
      font-size: 0.26rem;
      font-weight: bold;
      color: #edfcff;
    }

    .english {
      font-size: 0.16rem;
      color: #edfcff;
      text-transform: Uppercase;
    }
  }

  .role-men {
    position: absolute;
    left: 0;
    top: 1.4rem;
    width: 100%;
    height: 4rem;
    display: flex;
    align-items: flex-end;
    justify-content: center;

    img {
      width: 2.7rem;
      // height: 4rem;
      margin-top: 0.6rem;
    }
  }
}

.role-one {
  // opacity: 0;
  position: relative;
  z-index: 0;
  display: flex;
  justify-content: center;
  width: 5.85rem;
  height: 100%;

  // background: rgba(0, 0, 0, 0.7);
  // border-right: 0.01rem solid rgba(176, 29, 31, 0.4);
  .role-pic {
    width: 0.49rem;
    height: 3.19rem;
    margin-top: 2.4rem;
  }

  .role-hover {
    // left: 0;
    position: absolute;
    top: 0rem;
    // right: 0;
    bottom: 0;
    z-index: 10;
    width: 4.6rem;
    height: 8.77rem;
    background: url(../assets/images/flag1.png) no-repeat top;
    background-size: 100% auto;

    .btn-tg {
      position: absolute;
      bottom: 2.59rem;
      left: calc(50% - 2.42rem / 2);
      width: 2.42rem;
      height: 0.58rem;
      margin: 0 auto;
      background: url(../assets/images/btn_tg.png) no-repeat center;
      background-size: 100% auto;
    }

    .btn-tg:hover {
      background: url(../assets/images/btn_tg_hover.png) no-repeat center;
      background-size: 100% auto;
    }
  }
}

.btn-in {
  width: 2.54rem;
  height: 2.45rem;
  background: url(../assets/images/tougao1.png) no-repeat;
  background-size: cover;
  transform: scale(0.9);

  &:hover {
    background: url(../assets/images/tougao2.png) no-repeat;
    background-size: cover;
    transform: scale(0.9);
  }

  animation: buttons 1.5s infinite ease-in-out;
}

.location {
  display: block;
  margin: 2rem auto 0 auto;
  width: 8.11rem;
  height: 1.04rem;
  background: url(../assets/images/yaoqing.png) no-repeat;
  background-size: cover;
}

.btn-hdzq {
  background: url(../assets/images/interaction.png) no-repeat;
  background-size: 100% auto;
  background-position: 0 0 !important;

  &:hover {
    background: url(../assets/images/interaction_hover.png) no-repeat;
    background-size: 100% auto;
  }
}

@keyframes buttons {
  0% {
    transform: translate(0, 0) scale(0.9);
  }

  50% {
    transform: translate(0, -0.03rem) scale(0.9);
  }

  100% {
    transform: translate(0, 0) scale(0.9);
  }
}

.yuansu {
  position: absolute;
  top: 1.55rem;
  left: 4.93rem;
  width: 1.8rem;
  height: 3.17rem;
  background: url(../assets/images/yuansu.png) no-repeat;
  background-size: 100% auto;
  -webkit-animation-delay: 0.5s; //延迟
  animation-delay: 0.5s;
}

.delay {
  --animate-delay: 0.5s;
}
</style>
